import {
  getTextKeyFromI18Expansion,
  getTextParamsFromI18Expansion
} from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { ComponentScriptGroups } from '@aurora/shared-generated/types/graphql-schema-types';
import type { GlobalCss } from '@aurora/shared-types/components';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { collapseWhitespace } from '@aurora/shared-utils/helpers/objects/StringHelper';
import { getLog } from '@aurora/shared-utils/log';
import HTMLReactParser, { type DOMNode } from 'html-react-parser';
import Head from 'next/head';
import Script from 'next/script';
import React, { useMemo } from 'react';
import sharedCoreComponentRegistry, {
  type SharedEndUserComponent
} from '../../../features/sharedCoreComponentRegistry';
import CustomComponentScripts from './CustomComponentScripts';

interface DOMElement {
  name: string;
  attribs: Record<string, unknown>;
  children: DOMElement[];
  data: unknown;
}

const log = getLog(module);

function parseJson(json) {
  try {
    return JSON.parse(json);
  } catch (error) {
    log.error(error, 'Unable to parse json: %O', json);
    return {};
  }
}

function transform(node: DOMNode & DOMElement, globalCss: GlobalCss, formatMessage: FormatMessage) {
  if (node.type === 'script') {
    const body = node.children;
    if (body.length > 0) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Script {...node.attribs} strategy={'afterInteractive'}>{`${body[0].data}`}</Script>;
    } else {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Script {...node.attribs} strategy={'afterInteractive'} />;
    }
  } else if (node.type === 'tag') {
    switch (node.name) {
      case 'li:component': {
        const widget = sharedCoreComponentRegistry.getWidgetDescriptor(
          node.attribs?.id as SharedEndUserComponent
        );
        if (widget) {
          log.debug('rendering shared component %s for custom component', node.attribs.id);
          const WrappedComponent = widget.component;
          const props = node.attribs.props ? parseJson(`${node.attribs.props}`) : {};
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} />;
        }
        log.debug('shared component with id: %s not found', node.attribs?.id);
        break;
      }
      case 'li:i18n': {
        let values = {};
        if (node.attribs.values) {
          try {
            values = parseJson(`${node.attribs.values}`);
          } catch (error) {
            log.error('Failed to parse i18n values for custom component.', error);
          }
        }
        const message = formatMessage(node.attribs.key, values);
        return <>{message}</>;
      }
      default: {
        if (node.attribs) {
          if (node.attribs['class']) {
            const collapsedClassNames = collapseWhitespace(`${node.attribs['class']}`);
            if (collapsedClassNames.includes(' ')) {
              node.attribs['class'] = collapsedClassNames
                .split(' ')
                .map(className => globalCss?.tokens[className] || className)
                .join(' ');
            } else {
              const globalClassName = globalCss?.tokens[collapsedClassNames];
              if (globalClassName) {
                node.attribs['class'] = globalClassName;
              }
            }
          }
          Object.entries(node.attribs).forEach(([key, value]) => {
            if ((value as string).startsWith('<li:i18n')) {
              const textKey = getTextKeyFromI18Expansion(value as string);
              if (textKey) {
                const params = getTextParamsFromI18Expansion(value as string);
                node.attribs[key] = formatMessage(textKey, params);
              }
            }
          });
        }
      }
    }
  }
  return node;
}

interface Props {
  /**
   * The template ID.
   */
  templateId: string;

  /**
   * An i18n object specific to the component.
   */
  formatMessage: FormatMessage;
  /**
   * The HTML markup.
   */
  markup: string;

  /**
   * The script groups.
   */
  scriptGroups?: ComponentScriptGroups;

  /**
   * Global CSS.
   */
  globalCss: GlobalCss;
}

/**
 * Renders body content for a Handlebars custom component
 * @param templateId
 * @param formatMessage
 * @param markup
 * @param scriptGroups
 * @param globalCss
 * @constructor
 */
const TemplateContent: React.FC<Props> = ({
  templateId,
  formatMessage,
  markup,
  scriptGroups,
  globalCss
}) => {
  if (!markup) {
    log.warn('No markup found for custom component: %s', templateId);
  }
  const transformedMarkup = useMemo(() => {
    return markup ? (
      HTMLReactParser(markup, {
        replace: node => {
          return transform(node as DOMNode & DOMElement, globalCss, formatMessage);
        },
        trim: true
      })
    ) : (
      <></>
    );
  }, [markup, formatMessage, globalCss]);
  return (
    <>
      {globalCss?.css && (
        <Head>
          <style
            type="text/css"
            key={`custom-${templateId}`}
            data-testid="CustomComponentContentCss"
          >
            {globalCss?.css}
          </style>
        </Head>
      )}
      {scriptGroups && <CustomComponentScripts scriptGroups={scriptGroups} />}
      {transformedMarkup}
    </>
  );
};

export default TemplateContent;
